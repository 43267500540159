/// <reference path="o365.pwa.declaration.sw.strategies.O365OfflineSyncStrategy.d.ts" />

import type { IO365ServiceWorkerGlobalScope } from 'o365.pwa.declaration.sw.O365ServiceWorkerGlobalScope.d.ts';
import type { StrategyHandler } from 'o365.pwa.declaration.sw.workbox.d.ts';
import type { Request, Response } from 'o365.pwa.declaration.sw.ServiceWorkerGlobalScope.d.ts';

import type * as O365OfflineSyncStrategyModule from 'o365.pwa.declaration.sw.strategies.O365OfflineSyncStrategy.d.ts';

declare var self: IO365ServiceWorkerGlobalScope;

(() => {
    class O365OfflineSyncStrategy extends self.workbox.strategies.Strategy implements O365OfflineSyncStrategyModule.O365OfflineSyncStrategy {
        constructor(options: O365OfflineSyncStrategyModule.O365OfflineSyncStrategyOptions = {}) {
            super(options);
        }

        async _handle(request: Request, handler: StrategyHandler): Promise<Response | undefined> {
            try {
                return await handler.fetchAndCachePut(request);
            } catch (reason) {
                const stringifiedReason = JSON.parse(JSON.stringify(reason, Object.getOwnPropertyNames(reason)));
                
                const responseBody = {
                    error: stringifiedReason
                };

                return new Response(JSON.stringify(responseBody), {
                    status: 500,
                    statusText: 'Internal Server Error',
                    headers: {
                        'Content-Type': 'application/json'
                    }
                });
            }
        }
    }

    self.o365.exportScripts<typeof import('o365.pwa.declaration.sw.strategies.O365OfflineSyncStrategy.d.ts')>({ O365OfflineSyncStrategy, self });
})();
